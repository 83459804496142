import revive_payload_client_4sVQNw7RlN from "/app/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/app/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/app/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_yVLowv6hDl from "/app/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_bs2bWDGRIE from "/app/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/app/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_UciE0i6zes from "/app/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_YdLad5Mpq3 from "/app/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/app/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/app/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_PSbK9A48ZQ from "/app/node_modules/pinia-plugin-persistedstate/dist/nuxt/runtime/plugin.js";
import plugin_TzdokXk5of from "/app/node_modules/nuxt-permissions/dist/runtime/plugin.mjs";
import plugin_vfCt6Q18Tc from "/app/node_modules/nuxt-auth-sanctum/dist/runtime/plugin.js";
import anchorscroll_plugin_H0zwu1Xy9f from "/app/node_modules/nuxt-anchorscroll/dist/runtime/anchorscroll-plugin.mjs";
import plugin_sb2LSEEy8f from "/app/node_modules/nuxt-quasar-ui/dist/runtime/plugin.js";
import switch_locale_path_ssr_5csfIgkrBP from "/app/node_modules/@nuxtjs/i18n/dist/runtime/plugins/switch-locale-path-ssr.js";
import i18n_sq1MuCrqbC from "/app/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.js";
import chartjs_1wC9lAM0Tp from "/app/plugins/chartjs.ts";
import i18n_VfGcjrvSkj from "/app/plugins/i18n.ts";
import mitt_client_JdecFmZkyR from "/app/plugins/mitt.client.js";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  payload_client_yVLowv6hDl,
  navigation_repaint_client_bs2bWDGRIE,
  check_outdated_build_client_8vK7RkfGxZ,
  chunk_reload_client_UciE0i6zes,
  plugin_vue3_YdLad5Mpq3,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  plugin_PSbK9A48ZQ,
  plugin_TzdokXk5of,
  plugin_vfCt6Q18Tc,
  anchorscroll_plugin_H0zwu1Xy9f,
  plugin_sb2LSEEy8f,
  switch_locale_path_ssr_5csfIgkrBP,
  i18n_sq1MuCrqbC,
  chartjs_1wC9lAM0Tp,
  i18n_VfGcjrvSkj,
  mitt_client_JdecFmZkyR
]