import {PERMISSIONS} from "~/common/permissions";

export const listOfNotProtectedRoutes = [
    "/",
    "/store",
    "/store/[store]",
    "/offers",
    "/auth/login",
    "/auth/register",
    "/auth/resetpassword",
    "/auth/forgotpassword",
    "/auth/verifyemail",
    "/auth/confirmPassword",
]

export const listOfProtectedRoutes = {
    "/admin/users" : [PERMISSIONS.READ_USERS],
    "/admin/users/create": [PERMISSIONS.CREATE_USERS],
    "/admin/roles" : [PERMISSIONS.READ_ROLES],
    "/admin/roles/create": [PERMISSIONS.CREATE_ROLES],
    "/admin/offers": [PERMISSIONS.READ_OFFERS],
    "/admin/offers/create": [PERMISSIONS.CREATE_OFFERS],
    "/products/create": [PERMISSIONS.CREATE_PRODUCTS],
    "/categories/create": [PERMISSIONS.CREATE_CATEGORIES],
    "/categories": [PERMISSIONS.READ_CATEGORIES],
}

export const listOfProtectedWithParamsRoutes = {
    "/admin/users/[id]": [PERMISSIONS.UPDATE_USERS],
    "/admin/roles/[id]": [PERMISSIONS.UPDATE_ROLES],
    "/admin/offers/[id]": [PERMISSIONS.UPDATE_OFFERS],
    "/products/[slug]/edit": [PERMISSIONS.UPDATE_PRODUCTS],
    "/categories/[id]": [PERMISSIONS.UPDATE_CATEGORIES]
}