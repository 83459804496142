// import { createI18n } from 'vue-i18n';
import es from "~/lang/es.json"
import en from "~/lang/en.json"
// import quasarEsLang from 'quasar/lang/es';
// import type {QuasarLanguage} from "quasar";

// const i18n = createI18n({
//     locale: getLocale(), // set locale
//     fallbackLocale: 'es', // set fallback locale
//     messages: {
//         es: es
//     },
//     legacy: false
// })

// export default i18n

// /**
//  * Get quasar lang.
//  *
//  * @return {QuasarLanguage}
//  */
// export function getQuasarLang(): QuasarLanguage {

//     switch (i18n.global.locale.value) {
//         case 'es':
//             return quasarEsLang
//         default:
//             return quasarEsLang
//     }
// }

// /**
//  * Get application locale.
//  *
//  * @returns {string}
//  */
// export function getLocale(): string {
//     return 'es';
//     //return document.documentElement? document.documentElement.lang : 'es'
// }

import { defineNuxtPlugin } from "#app"
import { createI18n } from "vue-i18n"

const messages = {
    en: en,
    es: es,
}

export default defineNuxtPlugin((nuxtApp) => {
    const i18n = createI18n({
        locale: "es", // Idioma por defecto
        messages,
    })

    //nuxtApp.vueApp.use(i18n);
})
