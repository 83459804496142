export enum PERMISSIONS {
    ALL = "ALL",

    //ORDERS
    READ_ORDERS = "read_orders",
    UPDATE_ORDERS = "update_orders",
    DELETE_ORDERS = "delete_orders",
    RESTORE_ORDERS = "restore_orders",
    CHANGE_STATUS_ORDERS = "change_status_orders",

    //COINS
    CREATE_COINS = "create_coins",
    READ_COINS = "read_coins",
    UPDATE_COINS = "update_coins",
    DELETE_COINS = "delete_coins",

    //ORDER STATUSES
    CREATE_ORDER_STATUSES = "create_orderStatuses",
    READ_ORDER_STATUSES = "read_orderStatuses",
    UPDATE_ORDER_STATUSES = "update_orderStatuses",
    DELETE_ORDER_STATUSES = "delete_orderStatuses",

    //PRODUCTS
    CREATE_PRODUCTS = "create_products",
    READ_PRODUCTS = "read_products",
    UPDATE_PRODUCTS = "update_products",
    DELETE_PRODUCTS = "delete_products",

    //CRITERIA
    CRITERIA_AND_ANSWER = "criteria_and_answer",
    CRITERIA = "criteria_products",
    UPDATE_CRITERIA = "update_criteria_products",
    CREATE_CRITERIA = "create_criteria_products",
    DELETE_CRITERIA = "delete_criteria_products",

    //ANSWERS
    ANSWER = "answer_products",
    UPDATE_ANSWER = "update_answer_products",
    CREATE_ANSWER = "create_answer_products",
    DELETE_ANSWER = "delete_answer_products",

    //RATING
    RATING = "rating_products",
    CREATE_RATING = "create_rating_products",
    STATUS_RATING = "status_rating_products",

    //CATEGORIES
    CREATE_CATEGORIES = "create_categories",
    READ_CATEGORIES = "read_categories",
    UPDATE_CATEGORIES = "update_categories",
    DELETE_CATEGORIES = "delete_categories",

    //USERS
    CREATE_USERS = "create_users",
    READ_USERS = "read_users",
    UPDATE_USERS = "update_users",
    DELETE_USERS = "delete_users",

    //EXCHANGE RATES
    CREATE_EXCHANGE_RATES = "create_exchange_rate",
    READ_EXCHANGE_RATES = "read_exchange_rate",
    UPDATE_EXCHANGE_RATES = "update_exchange_rate",
    DELETE_EXCHANGE_RATES = "delete_exchange_rate",

    //STORE REQUESTS
    READ_STORE_REQUESTS = "read_store_requests",
    APPROVE_STORE_REQUESTS = "approve_store_requests",
    REJECT_STORE_REQUESTS = "reject_store_requests",

    //ROLES
    CREATE_ROLES = "create_roles",
    READ_ROLES = "read_roles",
    UPDATE_ROLES = "update_roles",
    DELETE_ROLES = "delete_roles",

    //READ
    READ_POS = "read_pos",
    READ_ATTRIBUTES = "read_attributes",
    READ_INVOICES = "read_invoices",

    READ_OFFERS = "read_offers",
    CREATE_OFFERS = 'create_offers',
    UPDATE_OFFERS = 'update_offers',
    DELETE_OFFERS = 'delete_offers',
    READ_BANNERS = "read_banners",
    READ_REPORTS = "read_reports",
    READ_FOR_SALE_REPORTS = "read_for_sale_reports",
    READ_FOR_INVENTORY_REPORTS = "read_for_inventary_reports",
    READ_FOR_ORDER_REPORTS = "read_for_order_reports",
    READ_FOR_MOVEMENT_REPORTS = "read_for_movement_reports",
    READ_FOR_SALE_ACCOUNTING_REPORTS = "read_for_sales_accounting_reports",
    READ_FOR_SOLD_PRODUCTS = "read_for_sold_products",
    READ_POST = "read_post",
    RATING_PRODUCTS = "rating_products",
    READ_SETTINGS = "read_settings",
    READ_EXCHANGE_RATE = "read_exchange_rate",
    READ_ANALYTICS = "read_analytics",

    //UPDATE
    UPDATE_SETTINGS = "update_settings",
}
