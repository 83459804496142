import {listOfProtectedWithParamsRoutes, listOfProtectedRoutes} from "~/common/routes";

export const inferRouteParams = (path:string, params:any={}) => {
    const permissions = [] as Array<string>
    if(Object.keys(params).length > 0){
        for (const [keyRoute, permissionsRoute] of Object.entries(listOfProtectedWithParamsRoutes)) {
            let route = "";
            for (const [key, value] of Object.entries(params)) {
                route = keyRoute.replace(`[${key}]`,`${value}`)
            }
            if(route.includes(path)){
                permissions.push(...permissionsRoute)
            }
        }
    }else{
        if(path in listOfProtectedRoutes){
            permissions.push(...listOfProtectedRoutes[`${path}`])
        }
    }
    return permissions
}